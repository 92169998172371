footer.footer {
	@extend %block;
	background: $color--primary;
	padding: 0.25rem 0;
	color: $color--light;

	small {
		font-size: 0.8rem;
	}

	ul.footer-menu {
		@extend %block-flex;
		justify-content: flex-end;
		flex-wrap: wrap;

		li {
			list-style: none;
			width: 100%;
			display: block;
			margin-right: 0;

			@include for-size(md) {
				width: auto;
				display: inline;
				margin-right: $spacing--medium;
			}

			&:last-child {
				margin-right: 0;
			}

			&.current {
				a {
					text-decoration: underline;
				}
			}

			a {
				display: block;
				font-size: 0.8rem;
				font-weight: 300;
				text-align: right;
				padding: 0.25rem;
				color: $color--light;
				@include hyphens(auto);

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}