.button-top {
	position: fixed;
	bottom: -100px;
	right: 1rem;
	z-index: 9995;
	background: $color--primary;
	display: flex;
	align-items: center;
	align-content: center;
	padding: 0.75rem 1.5rem;
	text-align: center;
	font-weight: 700;
	font-size: 0.8rem;
	letter-spacing: 0.075rem;
	text-transform: uppercase;
	color: $color--light;
	@include radius(200px);
	@include transform(scale(1));
	@include shadow(box, none);
	pointer-events: none;
	opacity: 0;
	@include transition(all 0.35s $easing--in-out);

	&.show {
		bottom: 1rem;
		pointer-events: auto;
		opacity: 1;
		@include transition(all 0.35s $easing--in-out);
	}

	.icon {
		@include transform(rotate-90deg);

		img {
			height: 16px;
			width: auto;
		}
	}

	.label {
		position: relative;
		top: -1px;
		padding-left: 0;
		width: 0;
		white-space: nowrap;
		overflow: hidden;
		opacity: 0;
		@include transition(all 0.35s $easing--in-out);
	}

	&:hover {
		@include transform(scale(1.05));
		@include shadow(box, $shadow--medium);
		@include transition(all 0.25s $easing--in-out);

		.label {
			width: 102px;
			opacity: 1;
			padding-left: $spacing--small;
			@include transition(all 0.35s $easing--in-out);
		}
	}
}