.tile {
	@extend %block;
	@include radius($radius--small);
	overflow: hidden;
	position: relative;

	&.has--button {
		padding-bottom: calc(1.5rem + 41px);
	}

	&.bg--primary,
	&.bg--dark,
	&.bg--black-blur {

		.headline,
		.text {
			color: $color--light;
		}
	}

	figure {
		@extend %block;

		img {
			@extend %block;
		}
	}

	.content {
		@extend %block;
		padding: $spacing--medium;

		.headline {
			font-weight: 900;
			@extend %block-flex;
			justify-content: center;
			align-items: center;

			.icon {
				width: 60px;
				height: auto;
				margin-right: $spacing--small;
			}
		}

		.text {
			margin-top: $spacing--medium;
		}

		.buttons {
			display: block;
			width: calc(100% - 3rem);
			position: absolute;
			left: 1.5rem;
			bottom: 1.5rem;
		}

		a.button {
			display: inline-block;
			background: $color--light;
			margin-top: $spacing--small;
			margin-right: $spacing--small;
			padding: 0.5rem 0.75rem;
			border: 3px solid $color--primary;
			@include radius($radius--small);
			font-size: 1rem;
			font-weight: 700;
			@include transition(all 0.25s $easing--in-out);

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background: $color--primary;
				color: $color--light;
				@include transition(all 0.25s $easing--in-out);
			}

			@include for-size(md) {
				font-size: 1.05rem;
			}
		}

	}
}