a.logo {
	width: auto;
	height: auto;
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	align-items: center;

	img {
		width: auto;
		height: 20px;
		display: inline-block;

		@media (min-width: 450px) {
			height: 25px;
		}

		@include for-size(md) {
			height: 30px;
		}
	}
}