.ce-gallery, .ce-videogallery {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	-webkit-grid-template-columns: 1fr 1fr;
	-moz-grid-template-columns: 1fr 1fr;
	grid-gap: $spacing--medium;

	@include for-size(md) {
		grid-gap: $spacing--large;
	}
	
	@include for-size(md) {
		&.columns-2 {
			grid-template-columns: 1fr 1fr;
			-webkit-grid-template-columns: 1fr 1fr;
			-moz-grid-template-columns: 1fr 1fr;
		}
		&.columns-3 {
			grid-template-columns: 1fr 1fr 1fr;
			-webkit-grid-template-columns: 1fr 1fr 1fr;
			-moz-grid-template-columns: 1fr 1fr 1fr;
		}
		&.columns-4 {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			-webkit-grid-template-columns: 1fr 1fr 1fr 1fr;
			-moz-grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		&.columns-5 {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			-webkit-grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			-moz-grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}
	
	
	a.ce-gallery-image, a.ce-gallery-video {
		display: block;
		width: 100%;
		z-index: 1;
		@include transform(scale(1));
		@include transition(all 0.35s $easing--in-out);
		
		&:hover {
			z-index: 5;
			@include transform(scale(1.1));
			@include transition(all 0.35s $easing--in-out);
			
			figure.image {
				@include shadow(box, $shadow--medium);
				@include transition(all 0.35s $easing--in-out);
			}
		}
		
		figure.thumbnail {
			@extend %block;
			background-color: $color--light;
			position: relative;
			padding-top: 100%;
			@include transition(all 0.35s $easing--in-out);
			
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		figcaption.caption {
			@extend %block;
			font-size: 1em;
			text-align: center;
			margin-top: $spacing--small;
		}
	}

	a.ce-gallery-video {
		figure.thumbnail {
			padding-top: 56.25%;

			img {
				&.icon-play {
					object-fit: fill;
					width: auto !important;
					height: 64px !important;
					left: 50%;
					top: 50%;
					@include transform(translateX(-50%) translateY(-50%));
					z-index: 5;
					@include drop-shadow($shadow--medium);
				}
			}
		}
	}
}