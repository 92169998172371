.swiper-wrapper {
	@extend %block-flex;
}

.swiper-container {
	position: relative;
}


.swiper-slide {
	align-items: stretch;
	height: auto !important;

	img.slide-img {
		@extend %block;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		object-fit: cover;
	}

	.swiper-slide--inner {
		@extend %block-flex;
		justify-content: center;
		align-items: flex-end;
		height: 100%;
		padding: $spacing--large 0;

		.slide-content {
			@extend %block;
			color: $color--light;
			background: rgba(57,57,57,0.6);
			padding: $spacing--large;
			@include backdrop-filter(blur(10px));
			@include radius($radius--small);

			p.tag {
				display: inline;
				width: auto;
				font-size: 0.8rem;
				font-weight: 700;
				letter-spacing: 0.05rem;
				background: $color--primary;
				padding: 0.25rem 0.5rem;
				@include radius(3px);
			}

			.headline {
				h3 {
					font-size: 1.45rem;
					line-height: 1.15;
					font-weight: 900;
					letter-spacing: 0.05rem;

					@include for-size(sm) {
						font-size: 1.7rem;
					}

					@include for-size(md) {
						font-size: 1.95rem;
					}

					@include for-size(lg) {
						font-size: 2.2rem;
					}
				}
			}
		}
	}
}

.pagination {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;

	.swiper-pagination {
		@extend %block-flex;
		align-items: flex-start;
		position: static;
		bottom: $spacing--large;

		.swiper-pagination-bullet {
			opacity: 0.35;
			opacity: 1;
			width: 12px;
			height: 12px;
			background: $color--light;
			margin-right: calc($spacing--small * 0.5);
			border-radius: 3px;

			&:last-child {
				margin-right: 0;
			}

			&.swiper-pagination-bullet-active {
				background: $color--primary;
			}
		}
	}
}
