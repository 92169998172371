main.main {
	@extend %block;
	margin: 0 auto;
	min-height: calc(100vh - 101.406px - 78px);
	
	&.layout--slim {
		.main--inner {
			padding: 2rem 0;
		}
		.row {
			max-width: map-deep-get($grid, max-width) - 500px !important;
		}
	}
	
	.main--inner {
		@extend %block;
	}


	@include for-size(md) {
		min-height: calc(100vh - 78.703px - 78px);
	}
}