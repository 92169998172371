/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin for-size($breakpoint) {
	$raw-query: map-deep-get($grid, "breakpoints", $breakpoint);
	
	@if $raw-query {
		$query: if(
				type-of($raw-query) == 'string',
				unquote($raw-query),
				inspect($raw-query)
		);
		
		@media (min-width: #{$query}) {
			@content;
		}
	} @else {
		@error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
	}
}