.ce-image {
	@extend %block;
	position: relative;
	@include radius($radius--small);
	overflow: hidden;

	a.img-link {
		@extend %block;
	}

	img {
		@extend %block;
	}

	figcaption {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		display: block;
		background: rgba(0,0,0,0.75);
		padding: 0.5rem;
		font-weight: 400;
		font-size: 0.75rem;
	}
}
