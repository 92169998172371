/// Theme helper – defines variables for a specific theme
/// @access private
/// @author dba.codes – Daniel Baumert
/// @param {Object} $themes - Object that contains all defined themes

/**
 * 1. Theme name
 * 2. Color variables
 * 3. Typography variables
 * 4. Radius variables
 * 5. Spacing variables
 * 6. Shadow variables
 */

/* 1 */
$theme: default;
/* 2 */
$color--primary: theme-property($theme, colors, primary);
$color--light: theme-property($theme, colors, light);
$color--grey-light: theme-property($theme, colors, grey-light);
$color--grey: theme-property($theme, colors, grey);
$color--grey-dark: theme-property($theme, colors, grey-dark);
$color--dark: theme-property($theme, colors, dark);
$color--font-base: theme-property($theme, colors, font-base);
$color--font-headline: theme-property($theme, colors, font-headline);
/* 3 */
$font--base-size: theme-property($theme, typography, base-size);
$font--base-family: theme-property($theme, typography, base-family);
$font--headline-family: theme-property($theme, typography, headline-family);
/* 4 */
$radius--small: theme-property($theme, radius, sm);
$radius--medium: theme-property($theme, radius, md);
$radius--large: theme-property($theme, radius, lg);
/* 5 */
$spacing--small: theme-property($theme, spacing, sm);
$spacing--medium: theme-property($theme, spacing, md);
$spacing--large: theme-property($theme, spacing, lg);
/* 6 */
$shadow--small: theme-property($theme, shadow, sm);
$shadow--medium: theme-property($theme, shadow, md);
$shadow--large: theme-property($theme, shadow, lg);
/* 7 */
$easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);
