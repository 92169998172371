.steps {
	background: $color--grey-light;
	padding: $spacing--medium !important;
	@include radius($radius--small);

	.headline {
		@extend %block;
		text-align: center;
		@include hyphens(auto);
		font-weight: 300;
		font-size: 1.45rem;

		strong {
			font-weight: 900;
		}

		@include for-size(sm) {
			font-size: 1.7rem;
		}

		@include for-size(md) {
			font-size: 1.95rem;
		}

		@include for-size(lg) {
			font-size: 2.2rem;
		}

	}

	.step {
		@extend %block-flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		figure.icon {
			display: inline-block;
			width: auto;
			height: auto;
			margin-right: $spacing--medium;

			img {
				width: 60px;
				height: auto;
				display: block;
			}
		}

		.title {
			display: inline-block;
			width: auto;
			height: auto;
			text-align: center;
			font-weight: 900;
			@include hyphens(auto);
			font-size: 1.25rem;

			@include for-size(sm) {
				font-size: 1.35rem;
			}

			@include for-size(md) {
				font-size: 1.45rem;
			}

			@include for-size(lg) {
				font-size: 1.55rem;
			}
		}

		.description {
			margin-top: $spacing--small;
		}
	}
}