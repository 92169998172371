.video {
	@extend %block;
	position: relative;

	video {
		@extend %block;
	}

	.yt, .vimeo {
		@extend %block;
		position: relative;
		padding-top: 56.25%;
		position: relative;
		z-index: 2;

		iframe {
			width: 100% !important;
			height: 100% !important;
			top: 0;
			left: 0;
			position: absolute;

			&:not([src]) {
				display: none;
			}
		}
	}

	.error-modal-container {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0,0,0,0.75);
		opacity: 1;
		pointer-events: none;
		display: none;

		.error-modal {
			width: 500px;
			max-width: 100%;
			height: auto;
			display: block;
			background: $color--light;
			padding: 3rem;

			h5, p {
				@extend %block;
				text-align: center;
				color: $color--dark;
			}

			p {
				margin-top: 1.5rem;
			}
		}
	}
}