.text {
	@extend %block;
	font-size: 1.05rem;
	line-height: 1.5;
	font-weight: 300;

	@include for-size(md) {
		font-size: 1.15rem;
	}

	p {
		@extend %block;
		font-weight: 300;
		column-gap: 2rem;
		-webkit-column-gap: 2rem;
		-moz-column-gap: 2rem;
		columns: 1;
		-webkit-columns: 1;
		-moz-columns: 1;
		@include hyphens(auto);

		strong {
			font-weight: 900;
		}
	}

	a {
		display: inline;
		font-weight: 300 !important;
		letter-spacing: 0 !important;
		text-decoration: underline;
		color: inherit !important;
	}

	ol {
		@extend %block;
		list-style-type: decimal;
		padding: 0;

		li {
			margin-bottom: 0.25rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ul {
		@extend %block;
		padding: 0;
		margin-top: 0.5rem;

		li {
			@extend %block;
			list-style: none;
			position: relative;
			padding: 0;
			padding-left: 1rem;
			margin-bottom: 0.5rem;

			strong {
				font-weight: 700;
			}

			&:before {
				content: '';
				width: 5px;
				height: 5px;
				position: absolute;
				top: 12px;
				left: 0;
				background: $color--primary;
				@include radius(100%);
			}
		}
	}

	figure.table {
		@extend %block;

		table {
			width: 100%;
			font-size: 0.85rem;
			margin-top: 0.25rem;
			border: none;
			border-collapse: collapse;
			table-layout: fixed;

			thead {
				tr {
					th {
						font-size: 1.1rem;
						font-weight: 700;
						letter-spacing: 0.025rem;
						text-transform: uppercase;
						color: $color--primary;
					}
				}
			}

			tbody {
				tr {
					th, td {
						text-transform: uppercase;
						padding: 0.75rem;
						border: 1px solid $color--primary;
						text-align: left;

						&:first-child {
							padding-left: 0;
							border-left: 0;
						}

						&:last-child {
							padding-right: 0;
							border-right: 0;
						}
					}

					th {
						font-size: 0.8rem;
						font-weight: 300;
						letter-spacing: 0.025rem;
						color: $color--primary;

						strong {
							font-size: 1.1rem;
							font-weight: 700;
						}
					}

					td {
						font-size: 1.1rem;
						font-weight: 700;
						text-align: center;

						i {
							font-style: normal;
							font-size: 0.95rem;
							color: $color--light;
							background: $color--primary;
							padding: 0.75rem;
							@include radius($radius--small);
						}
					}

					&:first-child {
						th, td {
							border-top: 0;
						}
					}

					&:last-child {
						th, td {
							border-bottom: 0;
						}
					}
				}
			}
		}
	}

	&.text-col-1 {
		p {
			columns: 1;
			-webkit-columns: 1;
			-moz-columns: 1;
		}
	}

	&.text-col-2 {
		p {
			@include for-size(sm) {
				columns: 2;
				-webkit-columns: 2;
				-moz-columns: 2;
			}
		}
	}

	&.text-col-3 {
		p {
			@include for-size(sm) {
				columns: 2;
				-webkit-columns: 2;
				-moz-columns: 2;
			}
			@include for-size(md) {
				columns: 3;
				-webkit-columns: 3;
				-moz-columns: 3;
			}
		}
	}

	&.text-col-4 {
		p {
			@include for-size(sm) {
				columns: 2;
				-webkit-columns: 2;
				-moz-columns: 2;
			}
			@include for-size(md) {
				columns: 3;
				-webkit-columns: 3;
				-moz-columns: 3;
			}
			@include for-size(lg) {
				columns: 4;
				-webkit-columns: 4;
				-moz-columns: 4;
			}
		}
	}
}
