.output-reference {
	display: grid;
	display: -moz-grid;
	grid-template-columns: 1fr;


	&.style--large, &.style--small {
		.reference-item {
			a {
				justify-content: center;
				align-items: center;
				position: relative;
				@include radius($radius--small);
				overflow: hidden;
				padding: $spacing--large;

				@include for-size(md) {
					padding-right: 33%;
				}

				figure.bg-img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					@include background-property(no-repeat !important, cover !important, center center !important);

					&:before {
						content: '';
						position: absolute;
						z-index: 1;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						@include gradient(linear, 0deg, rgba(0,0,0,0.75), rgba(0,0,0,0.2));
					}
				}

				.content {
					.icon-new {
						display: block;

						img {
							height: 52px;
							width: auto;
							display: block;
						}
					}
					.headline, .text {
						color: $color--light;
					}

					.headline {
						h3 {
							font-weight: 900;
							font-size: 1.45rem;

							@include for-size(sm) {
								font-size: 1.7rem;
							}

							@include for-size(md) {
								font-size: 1.95rem;
							}

							@include for-size(lg) {
								font-size: 2.2rem;
							}
						}
					}
				}
			}
		}
	}

	&.style--small {
		.reference-item {
			a {
				.content {
					position: relative;
					.headline {
						h3 {
							font-weight: 900;
							font-size: 1.25rem;

							@include for-size(sm) {
								font-size: 1.35rem;
							}

							@include for-size(md) {
								font-size: 1.45rem;
							}

							@include for-size(lg) {
								font-size: 1.55rem;
							}
						}
					}
					.text {
						display: none;
					}
				}
			}
		}
	}

	&.style--list {
		.reference-item {
			border-bottom: 2px dashed $color--font-base;

			&:first-child {
				border-top: 2px dashed $color--font-base;
			}

			a {
				justify-items: flex-start;
				align-items: stretch;
				padding: $spacing--medium 0;

				figure.bg-img {
					@include background-property(no-repeat !important, cover !important, center center !important);
					position: static;
					width: 25%;
					display: inline;
					@include radius($radius--small);
				}

				.content {
					width: 75%;
					display: inline;
					padding-left: $spacing--medium;
					padding-right: calc(3rem + 28px);

					.icon-new {
						display: block;
						position: absolute;
						top: 0;
						right: $spacing--medium;

						img {
							height: 28px;
							width: auto;
							display: block;
						}
					}

					.headline, .text {
						color: $color--font-base;
					}

					.headline {
						h3 {
							font-weight: 900;
							font-size: 1.25rem;

							@include for-size(sm) {
								font-size: 1.35rem;
							}

							@include for-size(md) {
								font-size: 1.45rem;
							}

							@include for-size(lg) {
								font-size: 1.55rem;
							}
						}
					}

					.text {
						display: none;
					}
				}
			}
		}
	}

	.reference-item {
		@extend %block;

		a {
			@extend %block-flex;
			@include transform(scale(1));
			@include transition(all 0.35s $easing--in-out);

			&:hover {
				@include transform(scale(1.02));
				@include transition(all 0.35s $easing--in-out);
			}

			.content {
				@extend %block;
				position: relative;
				z-index: 5;

				.placeholder-button {
					display: inline-block;
					background: $color--light;
					padding: 0.5rem 0.75rem;
					border: 3px solid $color--primary;
					@include radius($radius--small);
					font-size: 1rem;
					font-weight: 700;
					@include transition(all 0.25s $easing--in-out);

					&:hover {
						background: $color--primary;
						color: $color--light;
						@include transition(all 0.25s $easing--in-out);
					}

					@include for-size(md) {
						font-size: 1.05rem;
					}
				}
			}
		}
	}
}