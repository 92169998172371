form {
	@extend %block;
	margin-top: 0.75rem;
	margin-left: -18px;

	fieldset {
		@extend %block;
		border: none;

		h2 {
			@extend %block;
			float: left;
			font-size: 1.75rem;
			margin-bottom: 0.75rem;
			font-family: $font--headline-family;
		}

		.clearfix {
			width: 98%;
			height: auto;
			display: block;
			float: left;
			margin: 0 1% 1.5rem;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&.w50 {
				width: 48%;
				height: auto;
				display: inline;
			}

			&.w25 {
				width: 23%;
				height: auto;
				display: inline;
			}

			&.w75 {
				width: 73%;
				height: auto;
				display: inline;
			}

			label {
				@extend %block;
				font-size: 0.9rem;

				margin-bottom: 0.25rem;
				color: $color--dark;

				&[for*="privacy-policy"] {
					padding-left: 25px;
				}

				&[for*="call"] {
					padding-left: 25px;
				}

				&.add-on {
					position: absolute;
					left: 0;
					top: 0;
				}
			}

			.input {
				@extend %block;
				float: left;

				input,
				textarea {
					@extend %block;
					float: left;
					background: transparent;
					border: 1px solid $color--dark;
					color: $color--dark;
					padding: 0.75rem 1rem;
					font-weight: 500;
					font-size: 0.85rem;
					font-family: $font--base-family;
					@include radius($radius--small);
					outline: none;

					&:focus {
						border: 1px solid $color--primary;
					}

					&.error {
						border: 1px solid $color--primary;
					}

					&.add-on {
						width: auto;
					}
				}

				textarea {
					width: 100%;
					min-width: 100%;
					max-width: 100%;
					min-height: 300px;
					max-height: 800px;
					resize: none;
				}

				ul.inputs-list {
					@extend %block;
					float: left;


					li {
						list-style: none;
						display: inline;
						float: left;
						margin-right: 0.6rem;

						&:last-child {
							margin-right: 0;
						}

						label {
							position: relative;
							display: block;
							float: left;

							&.active {
								span {
									background: $color--primary;
									border: 1px solid $color--primary;
									color: $color--light;
									@include transition(all 0.35s $easing--in-out);
								}
							}

							input {
								display: none;
							}

							span {
								display: inline;
								float: left;
								font-weight: 500;
								font-size: 0.85rem;
								padding: 0.6rem 1.5rem;
								background: transparent;
								color: $color--dark;
								border: 1px solid $color--dark;
								cursor: pointer;
								@include radius($radius--small);
								@include transition(all 0.35s $easing--in-out);


								&:hover {
									background: $color--primary;
									color: $color--light;
									@include transition(all 0.35s $easing--in-out);
								}
							}
						}
					}
				}
			}
		}
	}

	.error,
	span.help-inline {
		font-weight: 700;
		font-size: 0.7rem;
		padding-top: 0.5rem;
		color: rgba(156, 10, 7, 1);
	}

	select {
		display: none;
	}

	.selectContainer {
		display: block;
		height: auto;
		width: auto;
		position: relative;

		&.active {
			.currentOption {
				color: $color--primary;
				border: 1px solid $color--primary;
				@include transition(all 0.35s $easing--in-out);

				&:after {
					@include transform(rotate(180deg));
					@include transition(all 0.35s $easing--in-out);
				}
			}

			.selectContainerInner {
				left: 0;
				opacity: 1;
				transition: left 0.01s, opacity 0.35s 0.1s $easing--in-out;
				-moz-transition: left 0.01s, opacity 0.35s 0.1s $easing--in-out;
				-webkit-transition: left 0.01s, opacity 0.35s 0.1s $easing--in-out;
			}
		}

		.currentOption {
			width: 100%;
			display: block;
			position: relative;
			border: 1px solid $color--dark;
			color: $color--dark;
			padding: 0.75rem 1rem;
			padding-right: 41px;
			font-weight: 500;
			font-size: 0.85rem;
			@include radius($radius--small);
			@include transition(all 0.35s $easing--in-out);
			cursor: pointer;

			&:after {
				content: '';
				position: absolute;
				top: 10px;
				right: 10px;
				height: 21px;
				width: 21px;
				background: url('/_Resources/Static/Packages/PX.Site/img/icon-chevron-down.svg');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				@include transform(rotate(0deg));
				@include transition(all 0.35s $easing--in-out);
			}
		}

		.selectContainerInner {
			@extend %block;
			left: -9999px;
			opacity: 0;
			position: absolute;
			top: 37px;
			z-index: 110;
			background: $color--light;
			@include radius(0 0 $radius--small $radius--small);
			border: 1px solid $color--primary;
			transition: opacity 0.35s $easing--in-out, left 0.01s 0.375s;
			-moz-transition: opacity 0.35s $easing--in-out, left 0.01s 0.375s;
			-webkit-transition: opacity 0.35s $easing--in-out, left 0.01s 0.375s;

			.selectOption {
				@extend %block;
				color: $color--dark;
				padding: 0.75rem 1rem;
				font-weight: 500;
				font-size: 0.85rem;
				cursor: pointer;
				@include transition(all 0.35s $easing--in-out);

				&:hover {
					background: $color--primary;
					color: $color--light;
					@include transition(all 0.35s $easing--in-out);
				}
			}
		}
	}

	.form-navigation {
		@extend %block;
		float: left;
		margin-top: 1.5rem;

		ul {
			@extend %block;
			float: left;
			display: flex;
			justify-content: center;
			align-items: center;

			li {
				list-style: none;
				display: inline;

				button {
					display: block;
					background: $color--light;
					color: $color--dark;
					padding: 0.5rem 0.75rem;
					font-size: 1rem;
					font-weight: 700;
					cursor: pointer;
					border: 3px solid $color--primary;
					@include radius($radius--small);
					@include transition(all 0.35s $easing--in-out);

					@include for-size(md) {
						font-size: 1.05rem;
					}

					&:hover {
						background: $color--primary;
						color: $color--light;
						@include transition(all 0.25s $easing--in-out);
					}
				}
			}
		}
	}
}