div.group {

	div.group--inner {
			--el--column-count: 1;
			display: grid;
			grid-template-columns: repeat(var(--el--column-count), 1fr);
			grid-template-rows: auto;
			gap: 	map-deep-get($grid, gutter, column-horizontal);

			@include for-size(sm) {
					--el--column-count: 2;
			}

			@include for-size(lg) {
					--el--column-count: 3;
			}
	}
}
