.ce-section {
	@extend %block;
	position: relative;

	figure.bg-image, video.bg-video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include background-size(cover !important);
		background-repeat: no-repeat !important;
		background-position: center center !important;
		z-index: 1;

		//BLEND MODE
		&.multiply {
			mix-blend-mode: multiply;
		}
		&.overlay {
			mix-blend-mode: overlay;
		}
		&.lighten {
			mix-blend-mode: lighten;
		}
		&.soft-light {
			mix-blend-mode: soft-light;
		}
		&.hard-light {
			mix-blend-mode: hard-light;
		}
		//FILTER
		&.b-w {
			filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
		}

		&.pl-fixed {
			background-attachment: fixed !important;
		}
	}

	video.bg-video {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.section-inner {
		@extend %block;
		position: relative;
		z-index: 5;
		padding: 2rem 0;
	}
}