ul.navigation--default {
	display: none;


	@media (min-width: 1300px) {
		display: flex;
		display: -webkit-flex;
		display: -moz-flex;
		justify-content: flex-end;
		align-items: stretch;
	}


	>li {
		list-style: none;
		position: relative;
		display: inline-flex;
		display: -webkit-inline-lex;
		display: -moz-inline-flex;
		align-items: stretch;

		&:hover {
			ul.navigation--default-sub {
				opacity: 1;
				pointer-events: auto;
				@include transition(all 0.35s $easing--in-out);
			}

			>a {
				color: $color--light;
				background: $color--primary;
			}
		}


		&:last-child {
			margin-right: 0;
		}

		&.current,
		&.active {
			a {
				background: none;
				color: $color--primary;
			}
		}

		&.active {
			>a {
				color: $color--primary;
				font-weight: 700;

				&:hover {
					cursor: default;
					background: transparent;
					color: $color--primary;
				}
			}
		}

		&.current {
			>a {
				color: $color--primary;
				font-weight: 700;

				&:hover {
					cursor: default;
					background: transparent;
					color: $color--primary;
				}
			}
		}

		a {
			@extend %block-flex;
			align-items: center;
			font-size: 1rem;
			color: $color--dark;
			white-space: nowrap;
			background: transparent;
			padding: 0 $spacing--medium;
		}

		ul.navigation--default-sub {
			min-width: 120%;
			position: absolute;
			top: 100%;
			left: 0;
			background: $color--primary;
			opacity: 0;
			pointer-events: none;
			@include transition(all 0.35s $easing--in-out);

			@include shadow(box, $shadow--medium);

			li {
				width: 100%;
				display: block;
				list-style: none;

				&:hover {
					>a {
						background: darken($color--primary, 5%);
						@include transition(all 0.35s $easing--in-out);
					}
				}

				&.current {
					>a {
						font-weight: 700;
						background: darken($color--primary, 5%);

						&:hover {
							cursor: default;
						}
					}
				}

				a {
					width: 100%;
					display: block;
					font-size: 1rem;
					color: $color--light;
					white-space: nowrap;
					padding: $spacing--medium;
					padding-top: $spacing--small;
					padding-bottom: $spacing--small;
					

					@include transition(all 0.35s $easing--in-out);
				}
			}
		}
	}
}


.toggle-menu {
	display: block;
	width: 40px;
	cursor: pointer;

	@media (min-width: 1300px) {
		display: none;
	}

	@media (hover: hover) {

		&:hover {
			span {
				&:nth-child(2) {
					left: 25%;
					@include transition(all 0.35s $easing--in-out);
				}
			}
		}
	}

	span {
		width: 100%;
		height: 3px;
		display: block;
		background: $color--grey-dark;
		position: relative;
		border-radius: $radius--large;

		&:first-child {
			@include transform(rotate(0deg) translateX(0) translateY(0));
			@include transition(all 0.35s $easing--in-out);
		}

		&:nth-child(2) {
			margin-top: 7px;
			width: 100%;
			left: 0;
			opacity: 1;
			@include transition(all 0.35s $easing--in-out);
		}

		&:last-child {
			margin-top: 7px;
			@include transform(rotate(0deg) translateX(0) translateY(0));
			@include transition(all 0.35s $easing--in-out);
		}
	}

	&.active {
		span {
			&:first-child {
				@include transform(rotate(45deg) translateX(6px) translateY(8px));
				@include transition(all 0.35s $easing--in-out);
			}

			&:nth-child(2) {
				width: 0;
				left: 50%;
				opacity: 0;
				@include transition(all 0.35s $easing--in-out);
			}

			&:last-child {
				@include transform(rotate(-45deg) translateX(6px) translateY(-8px));
				@include transition(all 0.35s $easing--in-out);
			}
		}
	}
}

nav.oc-navigation {
	position: fixed;
	z-index: 9997;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background: $color--light;
	@extend %block;
	padding-top: calc($spacing--large * 3);
	@include transform(translateX(100%));
	@include transition(all 0.35s $easing--in-out);


	@media (min-width: 1300px) {
		display: none;
	}

	&.active {
		@include transform(translateX(0));
		@include transition(all 0.35s $easing--in-out);
	}

	ul.navigation--default {
		@extend %block;

		li {
			width: fit-content;
			display: block;

			a {
				width: 100%;
				display: block;
				font-size: 1.5rem;
				padding: $spacing--medium;
			}

			ul.navigation--default-sub {
				display: none;
				min-width: unset;
				z-index: 200;
				padding-top: 2rem;

				li {
					a {
						&:hover {
							color: $color--light;
						}
					}
				}
			}
		}
	}
}

