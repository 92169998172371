.ce-column {
	position: relative;
	
	figure.background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-repeat: no-repeat !important;
		@include background-size(cover !important);
		background-position: center center !important;
		
		//BLEND MODE
		&.multiply {
			mix-blend-mode: multiply;
			background-blend-mode: multiply;
		}
		&.overlay {
			mix-blend-mode: overlay;
			background-blend-mode: overlay;
		}
		&.lighten {
			mix-blend-mode: lighten;
			background-blend-mode: lighten;
		}
		&.soft-light {
			mix-blend-mode: soft-light;
			background-blend-mode: soft-light;
		}
		&.hard-light {
			mix-blend-mode: hard-light;
			background-blend-mode:hard-light;
		}
		//FILTER
		&.b-w {
			filter: grayscale(100%);
			-moz-filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
		}
	}
	
	.neos-contentcollection {
		position: relative;
		z-index: 5;
	}
}