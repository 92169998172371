aside.sidebar {
	display: inline;
	height: calc(100vh - 93px);
	@include background-property(no-repeat !important, cover !important, center center !important);
	
	&.sticky {
		position: sticky;
		position: -webkit-sticky;
		top: 93px;
	}
	
	.row {
		height: calc(100vh - 93px);
	}
	
	a.btn-download {
		width: 100%;
		max-width: 150px;
		height: 150px;
		display: block;
		position: relative;
		background: $color--primary;
		border: 10px solid $color--light;
		@include radius(50%);
		@include transform(scale(1));
		@include shadow(box, $shadow--small);
		@include transition(all 0.35s $easing--in-out);
		animation-name: pulse;
		animation-duration: 14s;
		animation-iteration-count: infinite;
		animation-play-state: running;
		
		&:after {
			content: '';
			position: absolute;
			width: 150%;
			height: 150%;
			top: 50%;
			left: 50%;
			background: transparent;
			pointer-events: none;
			border: 4px solid $color--light;
			@include transform(translateX(-50%) translateY(-50%));
			@include radius(50%);
		}
		
		&:hover {
			@include transform(scale(1.1) !important);
			@include shadow(box, $shadow--medium !important);
			opacity: 1 !important;
			animation-play-state: paused;
			@include transition(all 0.35s $easing--in-out);
		}
		
		.label {
			position: absolute;
			top: 50%;
			left: 50%;
			@include transform(translateX(-50%) translateY(-50%));
			width: 100%;
			display: block;
			color: $color--light;
			text-align: center;
			font-size: 0.85rem;
			text-transform: uppercase;
			font-weight: 700;
			@include hyphens(auto);
			padding: $spacing--small;
		}
	}
}

@keyframes pulse {
	0% {
		@include transform(scale(1));
		opacity: 0.75;
	}
	14% {
		@include transform(scale(1.1));
		opacity: 1;
	}
	28%, 100% {
		@include transform(scale(1));
		opacity: 0.75;
	}
}