.story-extras {
	@extend %block;

	.contact-section {
		@extend %block;
		background: $color--grey;
		padding: 2rem 0;

		.headline {
			@extend %block;
			@include hyphens(auto);
			font-weight: 300;
			font-size: 1.25rem;

			strong {
				font-weight: 900;
			}

			@include for-size(sm) {
				font-size: 1.35rem;
			}

			@include for-size(md) {
				font-size: 1.45rem;
			}

			@include for-size(lg) {
				font-size: 1.55rem;
			}
		}
	}
	.gallery-section {
		@extend %block;
	}
	.tns-outer {
		cursor: grab;

		button, .tns-nav {
			display: none !important;
		}

		.gallery-item {
			width: auto;
			height: auto;

			img {
				height: 50vh;
				width: auto;
				display: block;
				object-fit: cover;
			}
		}
	}
}