.calendly-inline-widget {
	@extend %block;
	height: auto !important;
	min-height: 100vh !important;

	iframe {
		@extend %block;
		height: auto !important;
		min-height: 100vh !important;
	}
}