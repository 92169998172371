.accordion {
	@extend %block;

	.accordion-item {
		@extend %block;
		@include radius($radius--small);
		@include shadow(box, $shadow--small);
		overflow: hidden;
		margin-bottom: 0.75rem;

		&.bg--primary {
			background: $color--primary;
			color: $color--light;

			.item-content {
				border-top: 1px solid $color--light;
			}
		}

		&.bg--light {
			background: $color--light;
			color: $color--dark;

			.item-title {
				color: $color--primary;
			}

			.item-content {
				border-top: 1px solid $color--primary;
			}
		}

		&.bg--grey-light {
			background: $color--grey-light;
			color: $color--dark;

			.item-title {
				color: $color--primary;
			}

			.item-content {
				border-top: 1px solid $color--primary;
			}
		}

		&.bg--grey {
			background: $color--grey;
			color: $color--dark;

			.item-title {
				color: $color--primary;
			}

			.item-content {
				border-top: 1px solid $color--primary;
			}
		}

		&.bg--dark {
			background: $color--dark;
			color: $color--light;

			.item-content {
				border-top: 1px solid $color--light;
			}
		}
		
		&.bg--black-blur {
			background: rgba(57, 57, 57, 0.6);
			@include backdrop-filter(blur(10px));
			color: $color--light;

			.item-content {
				border-top: 1px solid $color--light;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.active {
			.item-title i {
				@include transform(rotateZ(45deg));
				@include transition(all 0.35s $easing--in-out);
			}
		}

		&.in--backend {
			.item-content {
				display: block !important
			}
		}

		.item-title {
			@extend %block;
			position: relative;
			padding: 1.5rem $spacing--medium;
			cursor: pointer;

			h5 {
				@extend %block;
				padding-right: 35px;
				font-size: 1rem;

				@include for-size(md) {
					font-size: 1.1rem;
				}

				@include for-size(lg) {
					font-size: 1.25rem;
				}
			}

			i {
				position: absolute;
				top: 50%;
				right: 2rem;
				margin-top: -13px;
				font-size: 1.5rem;
				@include transform(rotateZ(0deg));
				transform-origin: 50% 50%;
				@include transition(all 0.35s $easing--in-out);
			}
		}

		.item-content {
			width: 100%;
			display: none;
			padding: $spacing--medium;
		}
	}
}
