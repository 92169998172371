header {
	@extend %block;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	z-index: 9998;
	background: $color--light;
	margin: 0 auto;
	@include shadow(box, $shadow--small);

	.navi {
		@extend %inline-flex;
		justify-content: center;
		align-items: center;

		@media (min-width: 1300px) {
			justify-content: unset;
			align-items: unset;
		}
	}
}

.buttons--side {
	display: none;
	pointer-events: none;

	@media (min-width: 1350px) {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: fixed;
		top: 50%;
		right: 0;
		z-index: 20;
		@include transform(translateY(-50%));
	}

	a {
		@extend %block-flex;
		position: relative;
		right: 50px;
		margin-bottom: 20px;
		pointer-events: auto;
		justify-items: stretch;
		@include transform(translateX(100%));
		@include transition(all 0.35s);

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			right: 0;
			@include transform(translateX(0));
			@include transition(all 0.35s);
		}

		span {
			@extend %inline-flex;
			justify-content: center ;
			align-items: center;
			text-align: center;

			&.icon {
				width: 60px;
				height: auto;
				position:absolute;
  				top:50%;
  				bottom:50%;
				z-index: 9999;
  				transform:translate(-50%, -50%);
			}

			&.content {
				justify-content: flex-start;
				height:69px ;
				position: relative;
				font-size: 1.25rem;
				color: $color--font-base;
				background: $color--grey;
				padding: $spacing--medium;
				padding-left: 60px;
				flex: 1;
				@include shadow(box,$shadow--small);

				&:before {
					content: '';
					width: 38px;
					height: 100%;
					height:69px ;
					position: absolute;
					left: 0px;
					background: $color--grey;
				}
			}

		}
	}
}
